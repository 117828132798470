import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spin } from 'antd';

// pages
const LandingComponent = React.lazy(() =>
    import('../pages/Content/LandingComponent')
);
const About = React.lazy(() => import('../pages/Content/About'));
const Products = React.lazy(() => import('../pages/Content/Products'));
const Gallery = React.lazy(() => import('../pages/Content/Gallery'));
const Client = React.lazy(() => import('../pages/Content/Client'));
const Career = React.lazy(() => import('../pages/Content/Career'));

export function ContentComponent(props) {
    const { width, routes } = props;
    return (
        <Switch>
            <React.Suspense
                fallback={
                    <div
                        style={{
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Spin />
                    </div>
                }
            >
                <Route exact crossorigin path={routes.HOME}>
                    <LandingComponent
                        width={width}
                        // missionStatement={missionStatement}
                    />
                </Route>
                <Route path={routes.ABOUT}>
                    <About width={width} />
                </Route>
                <Route path={routes.PRODUCTS}>
                    <Products width={width} />
                </Route>
                {/* <Route path={routes.SERVICES}>
                <Services />
            </Route> */}
                <Route path={routes.GALLERY}>
                    <Gallery width={width} />
                </Route>
                <Route path={routes.CLIENT}>
                    <Client width={width} type={'client'} />
                </Route>
                <Route path={routes.PARTNERS}>
                    <Client width={width} type={'partner'} />
                </Route>
                <Route path={routes.CAREERS}>
                    <Career width={width} />
                </Route>
                <Redirect from="*" to={routes.HOME} />
            </React.Suspense>
        </Switch>
    );
}

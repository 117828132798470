const navbar = [
    {
        path: '/',
        name: 'home',
        icon: 'HomeOutlined',
        component: 'Landing',
        children: [],
    },
    {
        path: '/about',
        name: 'about',
        icon: 'InfoCircleOutlined',
        component: 'About',
        children: [],
    },
    {
        path: '/solutions',
        name: 'solutions',
        icon: 'ThunderboltOutlined',
        component: 'Products',
        children: [
            {
                path: '/solutions/fps',
                id: 'fps',
                name: 'Fire Protection System',
                children: [
                    {
                        path: '/solutions/detailed-system',
                        id: 'detailed-system',
                        name: 'Detailed Engineering & Design for Fire Safety Systems as per Norms',
                    },
                    {
                        path: '/solutions/fire-extinguisher-system',
                        id: 'fire-extinguisher-system',
                        name: 'Fire Extinguisher System',
                    },
                    {
                        path: '/solutions/fire-pump-room',
                        id: 'fire-pump-room',
                        name: 'Fire Pump Room',
                    },
                    {
                        path: '/solutions/fire-hydrant-system',
                        id: 'fire-hydrant-system',
                        name: 'Fire Hydrant System',
                    },
                    {
                        path: '/solutions/fire-sprinkler-system',
                        id: 'fire-sprinkler-system',
                        name: 'Fire Sprinkler System',
                    },
                    {
                        path: '/solutions/hva-spray',
                        id: 'hva-spray',
                        name: 'High / Medium Velocity Water Spray System',
                    },
                    {
                        path: '/solutions/pre-action-system',
                        id: 'pre-action-system',
                        name: 'Pre-Action System',
                    },
                    {
                        path: '/solutions/water-mist',
                        id: 'water-mist',
                        name: 'Water Mist System',
                    },
                    {
                        path: '/solutions/le-he-foam-system',
                        id: 'le-he-foam-system',
                        name: 'Low Expansion & High Expansion Foam System',
                    },
                    {
                        path: '/solutions/rim-seal-fps',
                        id: 'rim-seal-fps',
                        name: 'Rim Seal Fire Protection System',
                    },
                    {
                        path: '/solutions/n-injection-fps',
                        id: 'n-injection-fps',
                        name: 'Nitrogen Injection Transformer Fire Protection System',
                    },
                    {
                        path: '/solutions/gas-supression-novec-fm',
                        id: 'gas-supression-novec-fm',
                        name: 'Gas suppression system like Novec 1230/ FM 200',
                    },
                    {
                        path: '/solutions/kitchen-suppression',
                        id: 'kitchen-suppression',
                        name: 'Kitchen Suppression System',
                    },
                    {
                        path: '/solutions/tube-panel-fss',
                        id: 'tube-panel-fss',
                        name: 'Tube panel fire suppression system',
                    },
                    {
                        path: '/solutions/vehicle-fss',
                        id: 'vehicle-fss',
                        name: 'Vehicle Fire Suppression System',
                    },
                    {
                        path: '/solutions/fire-rated-doors',
                        id: 'fire-rated-doors',
                        name: 'Fire Rated Doors',
                    },
                    {
                        path: '/solutions/fire-mock-drills',
                        id: 'fire-mock-drills',
                        name: 'Fire Mock Drills',
                    },
                    {
                        path: '/solutions/fire-license-approval-renewal',
                        id: 'fire-license-approval-renewal',
                        name: 'Fire License Approval / Renewal',
                    },
                ],
            },
            {
                path: '/solutions/ibms',
                id: 'ibms',
                name: 'Integrated Building Management Systems',
                children: [
                    {
                        path: '/solutions/building-management-system',
                        id: 'building-management-system',
                        name: 'Building Management System',
                    },
                    {
                        path: '/solutions/fire-alarm-system',
                        id: 'fire-alarm-system',
                        name: 'Fire Alarm System',
                    },
                    {
                        path: '/solutions/voice-evacuation-system',
                        id: 'voice-evacuation-system',
                        name: 'Voice Evacuation System',
                    },
                    {
                        path: '/solutions/cctv',
                        id: 'cctv',
                        name: 'CCTV (IP & Analog)',
                    },
                    {
                        path: '/solutions/access-control-system',
                        id: 'access-control-system',
                        name: 'Access Control System',
                    },
                    {
                        path: '/solutions/pa-system',
                        id: 'pa-system',
                        name: 'Public Address System (PA)',
                    },
                    {
                        path: '/solutions/water-leak-detection',
                        id: 'water-leak-detection',
                        name: 'Water Leak Detection System',
                    },
                    {
                        path: '/solutions/rodent-repellent',
                        id: 'rodent-repellent',
                        name: 'Rodent Repellent System',
                    },
                    {
                        path: '/solutions/aspiration-system',
                        id: 'aspiration-system',
                        name: 'Aspiration System (Vesda)',
                    },
                    {
                        path: '/solutions/gas-leak-detection',
                        id: 'gas-leak-detection',
                        name: 'Gas Leak Detection System',
                    },
                    {
                        path: '/solutions/epabx-ipabx',
                        id: 'epabx-ipabx',
                        name: 'EPABX & IPABX',
                    },
                    {
                        path: '/solutions/data-voice-networking',
                        id: 'data-voice-networking',
                        name: 'Data and Voice Networking (Active & Passive)',
                    },
                    {
                        path: '/solutions/nurse-call-system',
                        id: 'nurse-call-system',
                        name: 'Nurse Call System',
                    },
                    {
                        path: '/solutions/networking',
                        id: 'networking',
                        name: 'Networking',
                    },
                ],
            },
            {
                path: '/solutions/ppe',
                id: 'ppe',
                name: "Personal Protective Equipment's (PPE)",
                children: [
                    {
                        path: '/solutions/head-to-toe-protection',
                        id: 'head-to-toe-protection',
                        name: 'Head to Toe Protection',
                    },
                    {
                        path: '/solutions/industrial-safety-system',
                        id: 'industrial-safety-system',
                        name: 'Industrial Safety System',
                    },
                    {
                        path: '/solutions/fixed-lifeline-system',
                        id: 'fixed-lifeline-system',
                        name: 'Fixed Lifeline System (Horizontal, Vertical, Gantry, Crane Bay)',
                    },
                    {
                        path: '/solutions/fixed-skylight-protection',
                        id: 'fixed-skylight-protection',
                        name: 'Fixed Skylight Protection System',
                    },
                    {
                        path: '/solutions/safety-eyewash-shower',
                        id: 'safety-eyewash-shower',
                        name: 'Safety Eye wash & Showers',
                    },
                    {
                        path: '/solutions/fall-arrestors',
                        id: 'fall-arrestors',
                        name: 'Fall arrestors',
                    },
                    {
                        path: '/solutions/cp-suits',
                        id: 'cp-suits',
                        name: 'Chemical Protection Suits',
                    },
                    {
                        path: '/solutions/hfr-suits',
                        id: 'hfr-suits',
                        name: 'Heat & Flame Retardant Suits',
                    },
                    {
                        path: '/solutions/eaf-suits',
                        id: 'eaf-suits',
                        name: 'Electrical Arc Flash Suits',
                    },
                    {
                        path: '/solutions/rescue-kit',
                        id: 'rescue-kit',
                        name: 'Rescue kit',
                    },
                ],
            },
            {
                path: '/solutions/iss',
                id: 'iss',
                name: 'Industrial Safety System',
                children: [
                    {
                        path: '/solutions/industrial-safety-system-sep',
                        id: 'industrial-safety-system-sep',
                        name: 'Industrial Safety System',
                    },
                    {
                        path: '/solutions/fl-system',
                        id: 'fl-system',
                        name: 'Fixed Lifeline System (Horizontal, Vertical, Gantry, Crane bay)',
                    },
                    {
                        path: '/solutions/flp-system',
                        id: 'flp-system',
                        name: 'Fixed Skylight Protection System',
                    },
                ],
            },
            // {
            //     path: '/solutions/fss',
            //     id: 'fss',
            //     name: 'Fire Suppression Systems',
            //     isItemGroup: true,
            //     children: [
            //         {
            //             path: '/solutions/fss',
            //             id: 'soln4',
            //             name: 'Solution 4',
            //         },
            //         {
            //             path: '/solutions/fss',
            //             id: 'soln5',
            //             name: 'Solution 5',
            //         },
            //         {
            //             path: '/solutions/fss',
            //             id: 'soln6',
            //             name: 'Solution 6',
            //         },
            //     ],
            // },
        ],
    },
    // {
    //     path: '/services',
    //     name: 'services',
    //     icon: 'ToolOutlined',
    //     component: 'Services',
    //     children: [
    //         {
    //             path: '/services/fls',
    //             id: 'fls',
    //             name: 'Fixed Lifeline Systems',
    //         },
    //         {
    //             path: '/services/rer',
    //             id: 'rer',
    //             name: 'Roof Edge Railing',
    //         },
    //     ],
    // },
    {
        path: '/gallery',
        name: 'gallery',
        icon: 'FileImageOutlined',
        children: [
            {
                path: '/gallery/afs',
                id: 'afs',
                name: 'Arc Flash Suit',
            },
            {
                path: '/gallery/bms',
                id: 'bms',
                name: 'BMS',
            },
            {
                path: '/gallery/bp',
                id: 'bp',
                name: 'Body Protection',
            },
            {
                path: '/gallery/cps',
                id: 'cps',
                name: 'Chemical Protection Suit',
            },
            {
                path: '/gallery/cs',
                id: 'cs',
                name: 'Cryogenic Suit',
            },
            {
                path: '/gallery/eeb',
                id: 'eeb',
                name: 'Emergency Escape Breating Device',
            },
            {
                path: '/gallery/fp',
                id: 'fp',
                name: 'Fall Protection',
            },
            {
                path: '/gallery/fip',
                id: 'fip',
                name: 'Fire Protection',
            },
            {
                path: '/gallery/fop',
                id: 'fop',
                name: 'Foot Protection',
            },
            {
                path: '/gallery/hp',
                id: 'hp',
                name: 'Hand Protection',
            },
            {
                path: '/gallery/hep',
                id: 'hep',
                name: 'Head Ear Protection',
            },
            {
                path: '/gallery/hfr',
                id: 'hfr',
                name: 'Heat Flame Resistant Suit',
            },
            {
                path: '/gallery/ia',
                id: 'ia',
                name: 'Intrusion Alarm',
            },
            {
                path: '/gallery/ncs',
                id: 'ncs',
                name: 'Nurse Call System',
            },
            {
                path: '/gallery/pas',
                id: 'pas',
                name: 'PA System',
            },
            {
                path: '/gallery/rp',
                id: 'rp',
                name: 'Respiratory Protection',
            },
            {
                path: '/gallery/ses',
                id: 'ses',
                name: 'Safety Eye Wash Shower',
            },
            {
                path: '/gallery/ss',
                id: 'ss',
                name: 'Safety Signage',
            },
            {
                path: '/gallery/scba',
                id: 'scba',
                name: 'SCBA',
            },
            {
                path: '/gallery/scp',
                id: 'scp',
                name: 'Spill Containment Pallet',
            },
            {
                path: '/gallery/pr',
                id: 'projects',
                name: 'Projects',
                isItemGroup: true,
                children: [
                    {
                        path: '/gallery/projects',
                        id: 'projects',
                        name: 'References',
                    },
                ],
            },
        ],
    },
    {
        path: '/clients',
        name: 'clients',
        icon: 'UserOutlined',
        children: [],
    },
    {
        path: '/partners',
        name: 'partners',
        icon: 'SketchOutlined',
        children: [],
    },
    {
        path: '/careers',
        name: 'careers',
        icon: 'LaptopOutlined',
        children: [],
    },
];

export default navbar;

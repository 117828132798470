import React from 'react';
import { Divider, Row, Col, Card, Typography, Tooltip } from 'antd';
import './FooterComponent.less';
import map from '../assets/map.png';

export function FooterComponent(props) {
    const { Title, Paragraph, Text, Link } = Typography;
    const { Meta } = Card;
    const { width, data } = props;

    const redirectToMaps = () => {
        window.open(data.mapLink);
    };

    return (
        <div className="footer-container">
            <Divider orientation="center" style={{ color: 'white' }}>
                CONTACT US
            </Divider>
            <Row
                align="middle"
                justify="space-between"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
                <Col
                    span={8}
                    md={8}
                    xs={24}
                    style={width < 576 ? { marginBottom: '2%' } : {}}
                >
                    <Card
                        hoverable
                        bordered={false}
                        cover={
                            <Tooltip
                                title={
                                    <>
                                        <Text strong style={{ color: 'white' }}>
                                            HAAS - Chennai
                                        </Text>
                                        <Paragraph style={{ color: 'white' }}>
                                            {data.addressLine1}
                                            {data.addressLine2}
                                        </Paragraph>
                                    </>
                                }
                                placement="top"
                            >
                                <img
                                    alt="location"
                                    src={map}
                                    height={250}
                                    width={250}
                                />
                            </Tooltip>
                        }
                        onClick={redirectToMaps}
                    >
                        <Meta
                            title="We are located at,"
                            // description={data.addressLine1 + data.addressLine2}
                            description="HAAS-CHENNAI"
                        />
                    </Card>
                </Col>
                <Col
                    span={8}
                    md={8}
                    xs={24}
                    style={width < 576 ? { marginBottom: '2%' } : {}}
                >
                    <Card
                        hoverable
                        title={
                            <Title
                                level={5}
                                // copyable={{
                                //     text: `${data.addressLine1},${data.addressLine2}`,
                                // }}
                            >
                                Visit us
                            </Title>
                        }
                        bordered={false}
                    >
                        <Text
                            strong
                            copyable={{
                                text: `${data.addressLine1}${data.addressLine2}`,
                            }}
                        >
                            Head Office
                        </Text>
                        <Paragraph>
                            {data.addressLine1 + data.addressLine2}
                        </Paragraph>
                        <Divider style={{ borderTop: '1px solid black' }} />
                        <div>
                            <Text
                                strong
                                copyable={{
                                    text: `${data.overseas1},${data.overseas2}`,
                                }}
                            >
                                Overseas Office
                            </Text>
                        </div>
                        <Text>{data.overseas1}</Text>
                        <Paragraph>{data.overseas2}</Paragraph>
                    </Card>
                </Col>
                <Col
                    span={8}
                    md={8}
                    xs={24}
                    style={width < 576 ? { marginBottom: '2%' } : {}}
                >
                    <Card
                        hoverable
                        title={<Title level={5}>Call us</Title>}
                        bordered={false}
                    >
                        <Paragraph>
                            Phone :{' '}
                            <Link href="tel:+91-44435-15606">
                                +91 44 43515606
                            </Link>
                        </Paragraph>
                    </Card>
                    <Card
                        hoverable
                        title={<Title level={5}>Mail us</Title>}
                        bordered={false}
                        style={{ marginTop: '2%' }}
                    >
                        <Paragraph>
                            E-Mail :{' '}
                            <Link href={`mailto:${data.mailId}`}>
                                {data.mailId}
                            </Link>
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <div style={{ textAlign: 'center', color: 'white' }}>
                © HAAS ENGINEERING
            </div>
            <div style={{ textAlign: 'center', color: 'white' }}>
                <em>
                    Website designed {' & '}developed by{' '}
                    <Link
                        href="https://www.linkedin.com/in/dyram-meyn-dev"
                        target="_blank"
                    >
                        DM Designs
                    </Link>
                </em>
            </div>
        </div>
    );
}

import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import './HomePage.less';

// custom components
import {
    HeaderComponent,
    ContentComponent,
    FooterComponent,
} from '../components';

// data
import navbar from '../utils/navbar';
import mainData from '../data/mainData.json';
import PATH from '../utils/routes';

// hooks
import useWindowDimensions from '../hooks/useWindowDimensions';

export default function HomePage(props) {
    const { Content } = Layout;
    const { width } = useWindowDimensions();

    return (
        <Layout
            className="main-layout-container"
            style={{
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <BrowserRouter>
                <HeaderComponent
                    navbar={navbar}
                    width={width}
                    data={mainData}
                />
                <Layout>
                    <Content
                        className={width < 576 ? 'element' : ''}
                        style={{
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        }}
                    >
                        <ContentComponent width={width} routes={PATH} />
                        <FooterComponent width={width} data={mainData} />
                    </Content>
                </Layout>
            </BrowserRouter>
        </Layout>
    );
}

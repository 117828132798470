import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import './Navbar.less';

// icons
// import * as Icons from '@ant-design/icons';

// utils
import { capitalize } from '../../utils/String/capitalize';

export function Navbar(props) {
    const { config, width } = props;
    const { Item, SubMenu } = Menu;
    const { pathname } = useLocation();
    const history = useHistory();

    const centerStyle = {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 'large',
        // padding: '4px',
    };

    // const getIcon = (iconName) => {
    //     let Icony = Icons[iconName];
    //     return Icony ? <Icony /> : <></>;
    // };

    const getDefaultSelectedKey = () => {
        let currentPathname = pathname.split('/')[1];

        let defaultKey = currentPathname === '' ? 'home' : currentPathname;

        // let defaultKey = config.filter(
        //     // (menuItem) => menuItem.path === `/${pathname.split('/')[1]}`
        //     (menuItem) => {
        //         console.log(menuItem);
        //         return menuItem.name === `${pathname.split('/')[1]}`;
        //     }
        // )[0]?.name;

        // console.log({ defaultKey, cond: pathname.split('/') });
        return defaultKey;
    };

    const menu = () => (
        <Menu
            mode={width < 576 ? 'vertical' : 'horizontal'}
            style={width < 576 ? {} : centerStyle}
            defaultSelectedKeys={getDefaultSelectedKey()}
            className="menu-back menu-back-extra"
        >
            {config.length > 0 &&
                config.map((menuItem) =>
                    menuItem?.children?.length > 0 ? (
                        <SubMenu
                            key={`SubmenuOf${menuItem.name.trim()}`}
                            // icon={getIcon(menuItem.icon)}
                            title={capitalize(menuItem.name)}
                            // style={width < 576 ? {} : { width: '10%' }}
                            popupClassName={width < 576 ? '' : 'haas-popup'}
                        >
                            {Object.values(menuItem?.children).map(
                                (subMenuItems) => {
                                    if (subMenuItems.children) {
                                        if (subMenuItems.isItemGroup) {
                                            return (
                                                <Menu.ItemGroup
                                                    key={`InnerSubment${subMenuItems.name.trim()}`}
                                                    title={capitalize(
                                                        subMenuItems.name
                                                    )}
                                                >
                                                    {Object.values(
                                                        subMenuItems?.children
                                                    ).map((innerSubmenu) => (
                                                        <Item
                                                            key={
                                                                innerSubmenu.id
                                                            }
                                                            style={
                                                                width < 576
                                                                    ? {}
                                                                    : {
                                                                          fontSize:
                                                                              'large',
                                                                      }
                                                            }
                                                        >
                                                            <Link
                                                                to={innerSubmenu.path.toString()}
                                                            >
                                                                {
                                                                    innerSubmenu.name
                                                                }
                                                            </Link>
                                                        </Item>
                                                    ))}
                                                </Menu.ItemGroup>
                                            );
                                        }
                                        if (width < 576) {
                                            return (
                                                <Item
                                                    key={`InnerSubmentMenuItem${subMenuItems.name.trim()}`}
                                                    style={
                                                        width < 576
                                                            ? {}
                                                            : {
                                                                  fontSize:
                                                                      'large',
                                                              }
                                                    }
                                                >
                                                    <Link
                                                        to={subMenuItems.path.toString()}
                                                    >
                                                        {capitalize(
                                                            subMenuItems.name
                                                        )}
                                                    </Link>
                                                </Item>
                                            );
                                        }
                                        return (
                                            <SubMenu
                                                key={`InnerSubment${subMenuItems.name.trim()}`}
                                                // icon={getIcon(menuItem.icon)}
                                                title={capitalize(
                                                    subMenuItems.name
                                                )}
                                                popupClassName={
                                                    width < 576
                                                        ? ''
                                                        : 'haas-popup-child'
                                                }
                                                onTitleClick={() =>
                                                    history.push(
                                                        subMenuItems.path.toString()
                                                    )
                                                }
                                            >
                                                {Object.values(
                                                    subMenuItems?.children
                                                ).map((innerSubmenu) => (
                                                    <Item
                                                        key={innerSubmenu.id}
                                                        style={
                                                            width < 576
                                                                ? {}
                                                                : {
                                                                      fontSize:
                                                                          'large',
                                                                  }
                                                        }
                                                    >
                                                        <Link
                                                            to={innerSubmenu.path.toString()}
                                                        >
                                                            {innerSubmenu.name}
                                                        </Link>
                                                    </Item>
                                                ))}
                                            </SubMenu>
                                        );
                                    }
                                    return (
                                        <Item
                                            key={subMenuItems.id}
                                            style={
                                                width < 576
                                                    ? {}
                                                    : { fontSize: 'large' }
                                            }
                                        >
                                            <Link
                                                to={subMenuItems.path.toString()}
                                            >
                                                {subMenuItems.name}
                                            </Link>
                                        </Item>
                                    );
                                }
                            )}
                        </SubMenu>
                    ) : (
                        <Item
                            key={menuItem.name}
                            // icon={getIcon(menuItem.icon)}
                            // style={width < 576 ? {} : { width: 'auto' }}
                        >
                            <Link to={menuItem.path.toString()}>
                                {capitalize(menuItem.name)}
                            </Link>
                        </Item>
                    )
                )}
        </Menu>
    );

    return (
        <>
            {width < 576 ? (
                <div
                    style={{
                        position: 'fixed',
                        top: 10,
                        width: '100%',
                        zIndex: 100,
                    }}
                >
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="bottomRight"
                        arrow
                    >
                        <Button onClick={(e) => e.preventDefault()}>
                            <MenuOutlined />
                        </Button>
                    </Dropdown>
                </div>
            ) : (
                menu()
            )}
        </>
    );
}

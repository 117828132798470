// styles
import './App.less';

// pages
import HomePage from '../pages/HomePage';

function App() {
    return <HomePage />;
}

export default App;

export default Object.freeze({
    ABOUT: '/about',
    PRODUCTS: '/solutions',
    SERVICES: '/services',
    GALLERY: '/gallery',
    CLIENT: '/clients',
    PARTNERS: '/partners',
    CAREERS: '/careers',
    HOME: '/',
});

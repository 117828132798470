import React from 'react';
import { Divider, Row, Col, Typography } from 'antd';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

// header comps
import { Navbar } from './Header/Navbar';

// assets
import logo from '../assets/nlr.png';

export function HeaderComponent(props) {
    const { navbar, width, data } = props;
    const { Text, Link, Paragraph } = Typography;

    return (
        <div
            style={
                width < 576
                    ? {
                          boxShadow: 'inset 0px -6px 6px -6px black',
                          padding: '0 0 10px 0',
                      }
                    : {}
            }
        >
            <div style={{ padding: '0% 1%' }}>
                <Row
                    align="middle"
                    justify="space-between"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                    <Col
                        span={7}
                        md={7}
                        xs={24}
                        style={
                            width < 576
                                ? { textAlign: 'center', marginTop: '2%' }
                                : {}
                        }
                    >
                        <img
                            src={logo}
                            style={{
                                width: '55%',
                                height: 'auto',
                                cursor: 'pointer',
                            }}
                            alt="Haas Engineering"
                            onClick={() => (window.location.href = '/')}
                        />
                    </Col>
                    {width > 576 && (
                        <Col
                            span={7}
                            md={7}
                            xs={24}
                            style={{ textAlign: 'center', cursor: 'pointer' }}
                        >
                            {/* <Text
                                strong
                                style={{
                                    fontSize: 'x-large',
                                    color: '#9e0f19',
                                }}
                                onClick={() => (window.location.href = '/')}
                            >
                                HAAS ENGINEERING
                            </Text> */}

                            <Paragraph
                                style={{
                                    // letterSpacing: '4px',
                                    fontSize: 'large',
                                    fontStyle: 'italic',
                                    color: '#9e0f19',
                                }}
                            >
                                {/* Systems {'&'} Solutions */}
                                Solutions for Your Safety
                            </Paragraph>
                        </Col>
                    )}
                    <Col
                        span={7}
                        md={7}
                        xs={24}
                        style={{
                            textAlign: width < 576 ? 'center' : 'right',
                            marginTop: width < 576 ? '1%' : '',
                            fontSize:
                                width < 576
                                    ? 'x-small'
                                    : width < 550
                                    ? 'large'
                                    : 'small',
                        }}
                    >
                        <>
                            <Text
                                strong
                                style={
                                    width < 576
                                        ? { color: '#9e0f19' }
                                        : {
                                              color: '#9e0f19',
                                          }
                                }
                            >
                                <PhoneOutlined
                                    style={
                                        width < 576
                                            ? { color: '#9e0f19' }
                                            : { color: '#9e0f19' }
                                    }
                                />{' '}
                                Phone :{' '}
                                <Link href="tel:+91-44435-15606">
                                    +91 44 43515606
                                </Link>
                            </Text>
                            <Divider type="vertical" />
                            <Text
                                strong
                                style={
                                    width < 576
                                        ? { color: '#9e0f19' }
                                        : { color: '#9e0f19' }
                                }
                            >
                                <MailOutlined
                                    style={
                                        width < 576
                                            ? { color: '#9e0f19' }
                                            : { color: '#9e0f19' }
                                    }
                                />{' '}
                                Mail :{' '}
                                <Link href={`mailto:${data.mailId}`}>
                                    {data.mailId}
                                </Link>
                            </Text>
                        </>
                    </Col>
                </Row>
            </div>

            <div>
                <Navbar config={navbar} width={width} />
            </div>
        </div>
    );
}
